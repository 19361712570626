import Utilities from "./Utilities";
import loMerge from 'lodash/merge';
import { setTimeout } from "core-js/es7";

export default class Configuration {

  constructor(configObject) {

    window.CookieConsent.buffer = {
      appendChild: [],
      insertBefore: []
    }

    // Wrapper filter function
    window.CookieConsent.callbacks = {}
    window.CookieConsent.wrapperRuns = []
    window.CookieConsent.wrapper = function (name = '', callback) {
      window.CookieConsent.callbacks[name] = this.callbacks[name] || []
      window.CookieConsent.callbacks[name].push(callback)
    };

    // Settings injector for users
    window.CookieConsent.setConfiguration = this.setConfiguration.bind(this);

    window.CookieConsent.config = {
      active: true,
      cookieExists: false,
      cookieVersion: 1,
      barTimeout: 1000,
      // showCloseButton: false,
      //useLocalStorage: true,
      //externalConfigUrl: 'http://koopsite.lomtec.me/cookieconsent-setting',
      //useThreeButtonBar: true,
      theme: {
        barColor: '#2C7CBF',
        barTextColor: '#FFF',
        barMainButtonColor: '#FFF',
        barMainButtonTextColor: '#2C7CBF',
        modalMainButtonColor: '#4285F4',
        modalMainButtonTextColor: '#FFF',
      },
      language: {
        current: 'en',
        locale: {
          en: {
            barMainText: 'This website uses cookies to ensure you get the best experience on our website.',
            barLinkSetting: 'Cookie Settings',
            barBtnAcceptAll: 'Accept all cookies',
            barBtnReject: 'Reject all cookies',
            modalMainTitle: 'Cookie settings',
            modalMainTextMore: 'Read more',
            modalMainTextMoreLink: null,
            modalMainText: 'Cookies are small piece of data sent from a website and stored on the user\'s computer by the user\'s web browser while the user is browsing. Your browser stores each message in a small file, called cookie. When you request another page from the server, your browser sends the cookie back to the server. Cookies were designed to be a reliable mechanism for websites to remember information or to record the user\'s browsing activity.',
            modalBtnSave: 'Save current settings',
            modalBtnAcceptAll: 'Accept all cookies and close',
            modalAffectedSolutions: 'Affected solutions:',
            learnMore: 'Learn More',
            on: 'On',
            off: 'Off',
            cookieName: 'Name',
            cookiePurpose: 'Description / purpose',
            cookieExpiration: 'Expiration',
            cookieType: 'Type',
            closeButtonText: 'Close',
            toggleSectionText: 'Toggle section'
          },
          hu: {
            barMainText: 'Ez a weboldal Sütiket használ a jobb felhasználói élmény érdekében.',
            barLinkSetting: 'Süti beállítások',
            barBtnAcceptAll: 'Minden süti elfogadása',
            modalMainTitle: 'Süti beállítások',
            modalMainText: 'A HTTP-süti (általában egyszerűen süti, illetve angolul cookie) egy információcsomag, amelyet a szerver küld a webböngészőnek, majd a böngésző visszaküld a szervernek minden, a szerver felé irányított kérés alkalmával. Amikor egy weboldalt kérünk le a szervertől, akkor a böngésző elküldi a számára elérhető sütiket. A süti-ket úgy tervezték, hogy megbízható mechanizmust biztosítsanak a webhelyek számára az információk megőrzésére vagy a felhasználók böngészési tevékenységének rögzítésére.',
            modalBtnSave: 'Beállítások mentése',
            modalBtnAcceptAll: 'Minden Süti elfogadása',
            modalAffectedSolutions: 'Mire lesz ez hatással:',
            learnMore: 'Tudj meg többet',
            on: 'Be',
            off: 'Ki',
            cookieName: 'Név',
            cookiePurpose: 'Leírás / célja',
            cookieExpiration: 'Lejárata',
            cookieType: 'Típus',
            closeButtonText: 'Close',
            toggleSectionText: 'Toggle section'
          }
        }
      },
      categories: {},
      services: {}
    }

    this.setConfiguration(configObject);

  }

  setConfiguration(configObject) {
    // The user overrides the default config
    loMerge(window.CookieConsent.config, configObject);

    // The cookie overrides the default and user config
    this.cookieToConfig(() => {
      // We tell the world we did this
      Utilities.dispatchEvent(document, 'CCConfigSet');
    });
  }

  cookieToConfig(callback) {

    function removeReload() {
      Utilities.removeCookie();
      Utilities.removeLocalStorage();
      //remove from external source
      if (window.CookieConsent.config.externalConfigUrl !== null && window.CookieConsent.config.externalConfigUrl !== undefined) {
        var interval = window.setInterval(() => {
          var externalIframe = document.getElementById('cconsent-external-iframe');
          if (externalIframe != null && externalIframe.dataset['loaded'] == 'true') {
            clearInterval(interval);
            externalIframe.contentWindow.postMessage(`{"action": "REMOVE"}`, window.CookieConsent.config.externalConfigUrl);
          }
        }, 10)

      }
      location.reload();
      return false;
    }

    function parseData(cookieData) {

      //only invalidate cookie if not using external config
      if (window.CookieConsent.config.externalConfigUrl == null || window.CookieConsent.config.externalConfigUrl == undefined) {

        // We check cookie version. If older we need to renew cookie.
        if (typeof cookieData.version === 'undefined') {
          return removeReload();
        } else {
          if (cookieData.version !== window.CookieConsent.config.cookieVersion) {
            return removeReload();
          }
        }

        // We check if cookie data categories also exist in user config
        for (let key in cookieData.categories) {

          // The cookie contains category not present in user config so we invalidate cookie
          if (typeof window.CookieConsent.config.categories[key] === 'undefined') {
            return removeReload();
          }
        }

        // We check if cookie data services also exist in user config
        cookieData.services.forEach(function (service) {

          // The cookie contains service not present in user config so we invalidate cookie
          if (typeof window.CookieConsent.config.services[service] === 'undefined') {
            return removeReload();
          }
        });        
      }

      //check expiration if set
      if(cookieData && cookieData.expiration !== undefined){
        var currentDate = Date.now();
        if (currentDate > cookieData.expiration)
          return removeReload();
      }

      // We we integrate cookie data into the global config object
      for (let key in cookieData.categories) {
        window.CookieConsent.config.categories[key].checked = window.CookieConsent.config.categories[key].wanted = (cookieData.categories[key].wanted === true) ? true : false;
      }
    }
    if (window.CookieConsent.config.externalConfigUrl !== null && window.CookieConsent.config.externalConfigUrl !== undefined) {
      window.addEventListener('message', function (event) {
        try {
          var cookieData = JSON.parse(event.data);
          if (cookieData !== null) {
            parseData(cookieData);
            window.CookieConsent.config.cookieExists = true;
          }
          callback();
        } catch {
        }
      })

      var interval = window.setInterval(() => {
        var externalIframe = document.getElementById('cconsent-external-iframe');
        if (externalIframe != null && externalIframe.dataset['loaded'] == 'true') {
          clearInterval(interval);
          externalIframe.contentWindow.postMessage(`{"action": "GET"}`, window.CookieConsent.config.externalConfigUrl);
        }
      }, 10)

    }
    else if (window.CookieConsent.config.useLocalStorage == true) { //use configuration from localstorate
      var localStorageItem = localStorage.getItem("cconsent");
      if (localStorageItem !== null) {
        var cookieData = JSON.parse(localStorageItem);

        parseData(cookieData);

        window.CookieConsent.config.cookieExists = true;
      }
      callback();
      return true;

    } else {
      document.cookie.split(';').filter((item) => {

        if (item.indexOf('cconsent') >= 0) {
          var cookieData = JSON.parse(item.split('=')[1]);

          parseData(cookieData);

          window.CookieConsent.config.cookieExists = true;
        }
        callback();
        return true;

      });
    }

    return false;
  }

}
